<template>
  <v-menu
    v-model="showDateMenu"
    :close-on-content-click="false"
    min-width="auto"
    offset-y
    transition="scale-transition"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
      filled dense rounded
        append-icon="mdi-calendar"
        v-bind="attrs"
        v-on="on"
        :value="value | dateFormat"
        clearable
        label=""
        readonly
      ></v-text-field>
    </template>
    <v-date-picker
      v-model="value"
      locale="ru-RU"
      no-title
      scrollable
      @input="dateInput()"
    >
    </v-date-picker>
  </v-menu>
</template>
<script>
export default {
  name: "DatePicker",
  props: ["input", "outputSetter"],
  components: {},
  data() {
    return {
        value: null,
        showDateMenu: false,
    };
  },
  filters: {
    dateFormat: function (date) {
      if (date == null) {
        return ''
      }
      let mdate = new Date(date)
      let options = {timeZone: 'Europe/Moscow', year: 'numeric', month: 'numeric', day: 'numeric'}
      return mdate.toLocaleDateString('ru-RU', options)
    }
  },
  methods: {
      dateInput(){
          this.showDateMenu = false
          this.outputSetter(this.value)
      }
  },
  beforeMount(){
      if(this.input != null && this.input != "")
        this.value = this.input
  }
};
</script>